.hamburget-button-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 80px;
    background-color: $white;
    z-index: 5;
    transition: 0.3s all;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        width: 74px;
        height: 80px;
    }
}
.hamburger-btn-scroll {
    height: 60px !important;
}
.hamburger-button {
    display: block;
    cursor: pointer;
    background-color: $white;
    border: 0;
    max-width: 100%;

}

.bar1,
.bar2,
.bar3 {
    width: 26px;
    height: 2px;
    background-color: $gray-hamburger;
    border-radius: 5px;
    cursor: pointer;
    margin: 6px 0;
    transition: 0.4s;
}
.change {
    background-color: $gray-hamburger;
    .hamburger-button {
        background-color: $gray-hamburger;
    }
    div {
        background-color: $white;
    }
}
.change .bar1 {
    transform: translate(0, 8px) rotate(-45deg);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    transform: translate(0, -8px) rotate(45deg);
}
