body {
    background-color: black;
}

.homepage-wrapper {
    background-image: url("../assets/homepage-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    min-height: 100vh; /* fall-back */
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: stretch;
    z-index: 0;
    overflow: hidden;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        height: 100vh;
    }
}

.sidebar-wrapper {
    background: rgba(0, 0, 0, 0.6);
    width: 42px;
    left: calc(100vw - 42px);
    z-index: 1;
    position: absolute;
    top: 0;

    border-left: 2px solid $gray-border;
    min-height: 100vh; /* fall-back */
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: stretch;
    box-sizing: border-box;
    a {
        cursor: pointer;
    }
    &.inverted {
        background-color: $white;
        border-color: $inverted-border-color;
        width: 42px;
        left: calc(100vw - 42px);
        @media (min-width: map-get($grid-breakpoints, sm)) {
            left: calc(100vw - 80px);
            width: 80px;
        }
        p {
            color: $black;
        }

        img {
            filter: invert(100%);
        }
    }
    @media (min-width: map-get($grid-breakpoints, sm)) {
        left: calc(100vw - 82px);
        width: 82px;
        height: 100vh;
        padding: 145px 0 80px;
        justify-content: space-around;
    }
}
.sidebar-content {
    padding: 150px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 0 20px;
        margin-bottom: 50px;
        padding: 150px 0 80px;
    }
    @media (min-height: 500px) {
        padding: 10px 0 80px;
    }
    @media (min-height: 750px) {
        padding: 150px 0 80px;
    }
    @media (hover: none) {
        @media (min-height: 50px) {
            padding: 150px 0 80px !important;
        }
    }
}
.sidebar-wrapper p {
    color: $white;
    font-weight: 700;
    font-size: 1.3rem;
    writing-mode: vertical-rl;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
}

.sidebar-countries {
    margin-bottom: 60px;
    img {
        position: relative;
        left: 18px;
        margin: 2.5rem 0;
    }

    @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 0 20px;
        margin-bottom: 50px;
    }
}

.sidebar-discover {
    button,
    a {
        cursor: pointer;
        background-color: transparent;
        display: inline-block;
        border: 0;
        display: flex;
        justify-content: center;
        width: 40px;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            display: inline-block;
            padding: 0 20px;
            width: unset;
        }
    }
    img {
        position: static;
        left: -6px;
        margin-top: 3rem;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            position: relative;
        }
    }
    p {
        letter-spacing: 0.4rem;
    }
    @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 0 20px;
    }
}

.homepage-main {
    max-width: 1240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 130px 6.66% 0;

    @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 8vh 6.66% 0;
    }

    h1 {
        font-size: 3.2rem;
        line-height: 4.2rem;
        color: $white;
        font-weight: 500;
        margin: 0;
        font-family: $font-inter;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            font-size: 5.5rem;
            line-height: 7rem;
            letter-spacing: 0.12rem;
        }
    }
}

.onlyfans-text {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: $gray-text;
    letter-spacing: 0.2rem;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        margin-top: 10vh;
    }
}
.agency-text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    max-width: 80%;
    font-weight: 500;
    color: $white;
    margin: 0.7rem 0 2.9rem;
    padding-left: 0;
    br {
        display: none;
    }
    @media (min-width: map-get($grid-breakpoints, sm)) {
        max-width: unset;
        font-size: 2rem;
        line-height: 3.4rem;
        br {
            display: unset;
        }
    }
}
.learn-more-btn {
    padding: 2.7rem 0rem 2.7rem 3rem;
    background-color: $white;
    display: flex;
    font-size: 1.3rem;
    font-weight: 700;
    width: 230px;
    color: $black;
    border: 0;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    cursor: pointer;
    text-decoration: none;
    img {
        margin-left: 2rem;
    }
}

.learn-more-btn::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #8e8e8e;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.learn-more-btn:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.homepage-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 84px;
    width: 100vw;
    border-bottom: 2px solid $gray-border;
    border-top: 2px solid $gray-border;
    padding: 0 20px;
    background-color: rgba(0, 0, 0, 0.3);
    @media (min-width: map-get($grid-breakpoints, sm)) {
        background-color: unset;
        padding: 0 50px;
    }
    > div {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 280px;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            padding: 0 50px;
            max-width: 360px;
        }
    }
    img {
        position: relative;
        top: -2px;
    }
    :first-child {
        color: $white;
    }
    p {
        font-size: 1.3rem;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.5);
        letter-spacing: 0.2rem;
    }
}
