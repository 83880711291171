.main-footer * {
    // font-family: $font-inter;
}

.main-footer-content {
    margin: 0 auto;
    margin-top: 3rem;
    max-width: 1240px;
    display: flex;
    padding: 0 2rem 2rem;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 0 1rem 1rem;
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
        margin-top: 7rem;
        padding: 0 2rem 2rem;
    }

    > div {
        width: 100%;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            width: 25%;
        }
    }
    h3 {
        font-size: 28px;
        line-height: 48px;
        text-transform: capitalize;
        color: $white;
        margin: 0 0 5px 0;
        font-weight: 600;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            font-size: 22px;
            line-height: 36px;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            font-size: 28px;
            line-height: 48px;
        }
    }
}

.footer-nav {
    display: flex;
    width: 20%;
    flex-direction: column;
    align-items: center;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        align-items: unset;
    }
    button,
    a {
        color: $white;
        font-size: 28px;
        line-height: 48px;
        text-transform: capitalize;
        text-decoration: none;
        position: relative;
        display: inline-block;
        font-weight: 600;
        border: 0;
        cursor: pointer;
        background: none;
    }

    a::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #adadad;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    a:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}
.footer-contact-wrapper,
.footer-follow-wrapper,
.footer-newsletter-wrapper {
    display: none;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        display: block;
    }
}
.footer-contact-wrapper {
    a {
        color: $gray-text;
        font-size: 16px;
        line-height: 25px;
        font-weight: 600;
    }
}
.footer-follow-wrapper {
    p {
        color: $gray-text;
        font-size: 16px;
        line-height: 25px;
        font-weight: 600;
        margin: 0;
    }
}

.social-media-footer {
    display: flex;
    margin-top: 8px;
    margin-bottom: 14px;

    img {
        filter: invert(85%);
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: $white;
    }
    a:not(:last-child) {
        margin-right: 10px;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            margin-right: 3px;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            margin-right: 10px;
        }
    }
}

.footer-newsletter-wrapper {
    p {
        color: $gray-text;
        font-size: 1.5rem;
        margin: 0;
    }
    input {
        position: relative;
        color: $dark-background;
        background-color: $white;
        border: 0;
        font-size: 1.4rem;
        line-height: 1.4rem;
        padding: 1.6rem;
        width: 100%;
    }

    button {
        position: absolute;
        top: 8px;
        right: 8px;
        background-color: $dark-background;
        border-radius: 50%;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        cursor: pointer;
        img {
            width: 1.8rem;
            filter: invert(100%);
        }
    }
    .newsletter-wrapper {
        position: relative;
        margin-top: 3.5rem;
    }
}
