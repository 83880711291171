.form-wrapper {
    background-color: #222222;
    padding: 2rem 2rem;
    width: 100%;
    display: inline-block;

    form {
        display: flex;
        flex-direction: column;
    }
    p {
        margin: 0.5rem 0;
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
        padding: 6rem 7rem;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
        width: 50%;
    }
    input,
    textarea {
        font-size: 1.6rem;
        padding: 1.8rem;
        border: 0;
        background-color: $subtitle-underline;
        margin-top: 1.8rem;
        color: $white;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
    input:focus-visible,
    textarea:focus-visible {
        outline: 1px solid #949393;
    }
    // Button should be refactored to reusable component
    button {
        margin-top: 1.8rem;
        margin-left: auto;
        padding: 2rem 2.2rem;
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: 0.3rem;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        background-color: $white;
        border: 0;
        color: $black;
        img {
            position: relative;
            top: 3px;
        }
    }

    button::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #8e8e8e;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    button:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}
.form-subtitle {
    font-size: 1.4rem;
    color: $white;
    line-height: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.form-title {
    color: $white;
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media (min-width: map-get($grid-breakpoints, md)) {
        font-size: 3rem;
        line-height: 3.2rem;
    }
}

.column-inputs {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > div {
        width: calc(50% - 1rem);

        input {
            width: 100%;
        }
    }
}
.error-message {
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: rgb(225, 30, 30);
}
