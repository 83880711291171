.about-us-content-wrapper {
    // max-width: 1240px;
    background-color: #222222;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        align-items: center;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
        flex-direction: row;
        align-items: unset;
    }
}
.inverted-about-us {
    .about-us-content-wrapper {
        @media (min-width: map-get($grid-breakpoints, sm)) {
            align-items: center;
        }

        @media (min-width: map-get($grid-breakpoints, lg)) {
            flex-direction: row-reverse !important;
        }
    }
}
.about-us-image {
    img{
        width: 100%;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
        width: 50%;
    }
}
.about-us-content {
    padding: 5rem 2rem;
    background-color: #222222;
    max-height: auto;
    max-width: 620px;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 9rem 5rem;
        max-height: 600px;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
        width: 50%;
    }
    .subtitle {
        color: $white;
        font-size: 1.2rem;
        line-height: 1.2rem;
        text-transform: uppercase;
        font-weight: 600;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            font-size: 1.4rem;
            line-height: 1.2rem;
            letter-spacing: 2.5px;
        }
    }
    .title {
        color: $white;
        font-size: 3rem;
        line-height: 3.2rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 2.2rem 0 3.5rem;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            font-size: 4rem;
            line-height: 5rem;
        }
    }

    p {
        color: $gray-text;
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: 500;
        letter-spacing: 0.5px;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            font-size: 1.7rem;
            line-height: 2.8rem;
        }
    }

    button {
        margin-top: 5rem;
        padding: 2rem 2.2rem;
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: 0.3rem;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        background-color: $white;
        border: 0;
        img {
            position: relative;
            top: 3px;
        }
    }

    button::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #8e8e8e;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    button:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}
