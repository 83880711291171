.homepage-header {
    margin: 0 80px 0 0;
    height: 80px;
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    z-index: 5;
    background-color: transparent;
    transition: 0.3s all;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 76px);
    }
}
.scrolled-header {
    background-color: $dark-background !important;
    height: 60px !important;
}

.scrolled-header::after {
    top: 60px !important;
}

.homepage-header > img {
    z-index: 2;
    margin-left: 80px;
    margin-top: 25px;
    display: none;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        margin-top: 0;
        display: inline-block;
    }
}
.homepage-header > .logo-link {
    margin-left: 80px;
    display: none;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        display: inline-block;
    }
}
.homepage-header::after {
    content: "";
    position: absolute;
    top: 80px;
    border-bottom: 2px solid $gray-border;
    width: 100vw;
    left: 0;
    transition: 0.3s all;
}
.social-media-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 290px;
    margin-right: 3rem;

    &.inverted {
        img {
            filter: invert(100%);
        }
        p {
            color: $black;
        }
    }
}

.social-media-options p {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: $white;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    display: none;

    @media (min-width: map-get($grid-breakpoints, sm)) {
        display: block;
    }
}
.social-media-options a {
    display: block;
    padding: 0.4rem;
}

.apply-now-btn {
    padding: 1.4rem 1rem;
    background-color: $white;
    display: flex;
    font-size: 1.3rem;
    font-weight: 700;
    width: 230px;
    color: $black;
    border: 0;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin-right: 3rem;
    img {
        margin-left: 2rem;
    }
}

.apply-now-btn::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #8e8e8e;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.apply-now-btn:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
