.acomplishment-item {
    width: 50%;
    height: 160px;
    background-color: #222;
    padding: 1.6rem 4rem;
    position: absolute;
    @media (min-width: map-get($grid-breakpoints, lg)) {
        padding: 3rem 4rem;
        height: 200px;
    }
    &.left1 {
        bottom: 160px;
        left: 0;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            bottom: 200px;
            height: 200px;
        }
    }
    &.left2 {
        bottom: 0;
        left: 0;
        border: 1px solid $gray-text;
        border-left: 0;
    }
    &.right1 {
        bottom: 160px;
        right: 0;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            bottom: 200px;
            height: 200px;
        }
    }
    &.right2 {
        bottom: 0;
        right: 0;
    }
    img {
        margin-bottom: 1rem;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            margin-bottom: 3rem;
        }
    }
    .acomplishment-title {
        font-size: 2.2rem;
        line-height: 2.2rem;
        margin: 0;
        color: $white;
        font-weight: 600;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 4rem;
            line-height: 4rem;
        }
    }

    .acomplishment-text {
        font-size: 1.2rem;
        line-height: 2.4rem;
        margin: 0;
        color: $gray-text;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 1.7rem;
            line-height: 3.4rem;
        }
    }
}

.about-us-left-image {
    .right2 {
        border-bottom: 1px solid $gray-text;
    }
}

.about-us-left-image,
.about-us-right-image {
    position: relative;
    > img {
        max-width: 100%;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
        width: 50%;
    }
}
