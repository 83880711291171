.news-item-wrapper {
    display: flex;
    flex-basis: 100%;
    background-color: #222;
    border: 1px solid #3b3b3b;
    flex-direction: column;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        flex-basis: 100%;
        flex-direction: row;
        &:nth-child(2n + 1) {
            flex-direction: row-reverse;
        }
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
        flex-basis: 50%;
        flex-direction: row !important;
        &:nth-child(4n - 1),
        &:nth-child(4n) {
            flex-direction: row-reverse !important;
        }
    }
    a {
        text-decoration: none;
    }
}

.news-image-wrapper {
    min-width: 100%;

    @media (min-width: map-get($grid-breakpoints, sm)) {
        width: 50%;
        min-width: 50%;
    }
    img {
        width: 100%;
        display: block;
    }
}

.news-content-wrapper {
    padding: 2.5rem 2rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        width: 50%;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
        padding: 0.5rem 2rem;
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
        padding: 1.5rem 2rem;
    }
    @media (min-width: map-get($grid-breakpoints, xl2)) {
        padding: 2rem 2rem;
    }
    p {
        color: $gray-text;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1.4rem;
        margin: 0;
    }
    h2 {
        color: $white;
        font-size: 2.4rem;
        line-height: 3.2rem;
        margin: 1rem 0;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            font-size: 2rem;
            line-height: 2.7rem;
        }

        @media (min-width: map-get($grid-breakpoints, xxl)) {
            font-size: 2.4rem;
            line-height: 3.2rem;
        }
    }
    .read-more-btn {
        cursor: pointer;
        margin-top: 1rem;
        padding: 1rem 0;
        font-size: 1.5rem;
        line-height: 2.2rem;
        font-weight: 700;
        background-color: #222;
        color: $white;
        border: 0;
        text-decoration: none;
        img {
            filter: invert(80%);
            position: relative;
            top: 3px;
            left: 10px;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            margin-top: 0;
        }

        @media (min-width: map-get($grid-breakpoints, xl)) {
            margin-top: 1rem;
        }
        @media (min-width: map-get($grid-breakpoints, xl)) {
            margin-top: 3rem;
        }
    }
}
