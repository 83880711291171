.services-content-wrapper {
    // max-width: 1240px;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        align-items: center;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
        flex-direction: row;
        align-items: unset;
    }
}

.services-content {
    padding: 5rem 2rem;
    min-height: 477px;
    @media (min-width: map-get($grid-breakpoints, sm)) {
        padding: 0rem 4rem;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
        padding: 4rem 5rem;
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
        padding: 6rem 5rem;
    }
    .subtitle {
        color: $white;
        font-size: 1.6rem;
        line-height: 2.2rem;
        text-transform: uppercase;
        font-weight: 400;

        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 2.4rem;
            line-height: 2.2rem;
        }
    }
    .title {
        color: $white;
        font-size: 2.8rem;
        line-height: 2.8rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 2.2rem 0 3.5rem;
        @media (min-width: map-get($grid-breakpoints, md)) {
            margin-top: 2rem;
            margin: 1.5rem 0 2rem;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 4rem;
            line-height: 4rem;
            margin: 2.2rem 0 3.5rem;
        }
    }

    p {
        color: $white;
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: 500;
        letter-spacing: 0.5px;
        @media (min-width: map-get($grid-breakpoints, sm)) {
            font-size: 1.5rem;
            line-height: 2.5rem;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            font-size: 1.7rem;
            line-height: 2.8rem;
        }
    }

    button,
    a {
        display: inline-block;
        margin-top: 5rem;
        padding: 2rem 2.2rem;
        font-size: 1.3rem;
        line-height: 2.2rem;
        letter-spacing: 0.3rem;
        font-weight: 700;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        background-color: $white;
        border: 0;
        color: $black;
        text-decoration: none;
        img {
            position: relative;
            top: 3px;
        }
        @media (min-width: map-get($grid-breakpoints, md)) {
            margin-top: 2rem;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            margin-top: 5rem;
        }
    }

    button::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #8e8e8e;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    button:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}

// Slider
.slider {
    width: 100vw;
    overflow: hidden;
}

.slider-content {
    overflow: hidden;
    width: 500vw;
    display: flex;
    position: relative;
    transition: all 1s ease;
}
.slide {
    width: 100vw;
    position: relative;
    left: 0;
}

.content-image {
    min-width: 40%;

    @media (min-width: map-get($grid-breakpoints, sm)) {
        max-width: 100%;
    }
    @media (min-width: map-get($grid-breakpoints, md)) {
        min-width: 54%;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
        min-width: 50%;
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
        min-width: 40%;
    }
    img {
        width: 100%;
        // height: 627px;
        // object-fit: contain;
    }
}

.services-content-wrapper::after {
    content: "";
    position: absolute;
    width: 100vw;
    height: 0.2rem;
    background-color: $subtitle-underline;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%);
}
