$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xl2: 1300px,
    xxl: 1400px,
);
$font-inter: "Inter", sans-serif;
$font-script: "Dancing Script", cursive;

// $gray-border: #474746;
$gray-border: rgba(255, 255, 255, 0.2);
$inverted-border-color: #e6e6e6;
$gray-hamburger: #1f1f1f;
$dark-background: #171717;
$subtitle-underline: #3c3c3c;
$gray-text: #727272;
$gray-light: #9a9a9a;
$black: #000;
$white: #fff;
html {
    font-size: 10px;
}

body {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: overlay;
    scrollbar-width: thin;
}
body::-webkit-scrollbar {
    width: 6px;
    position: absolute;
    border-radius: 4px;
    scrollbar-width: thin;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    width: 1rem;
    border-radius: 6px;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $dark-background;
}

body,
* {
    box-sizing: border-box;
    font-family: $font-inter;
}
