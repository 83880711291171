.error-page-wrapper {
    background-image: url("../assets/error-bg.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: top;
    width: 100%;
    height: 100vh;
    z-index: 0;

    .onlyfans-text {
        color: #f8f8f8;
        padding-left: 2px;
    }
    .homepage-main {
        padding-top: 190px !important;
    }
    .homepage-header {
        background-color: transparent !important;
    }

    .go-home-btn {
        text-decoration: none;
    }
}
