.homepage-overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    background-color: $white;
    visibility: visible;
    transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
    overflow: hidden;
    z-index: 2;
    &.close {
        width: 0px !important;
        visibility: hidden;
    }

    nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        padding: 21rem 3.9rem;
        a {
            text-decoration: none;
            font-size: 3rem;
            color: $black;
            font-weight: 500;
            padding: 0.8rem 11rem 0.8rem 6rem;
            width: 100%;
            white-space: nowrap;
        }
        a:hover {
            background-color: $inverted-border-color;
        }
        @media (min-width: map-get($grid-breakpoints, sm)) {
            padding: 21rem 8rem;
            a {
                font-size: 4rem;
                padding: 0.8rem 11rem 0.8rem 6rem;
            }
        }
    }

    .overlay-footer,
    .overlay-header {
        border-top: 2px solid $inverted-border-color;
        width: 480px;
        background-color: $white;
        position: absolute;
        bottom: 0;
        right: -10px;
        z-index: 100;
        height: 82px;
        display: flex;
        justify-content: flex-end;
    }
    .overlay-header {
        top: 0;
        z-index: 0;
        border-bottom: 2px solid $inverted-border-color;
        border-top: none;
        height: 80px;
    }
}
