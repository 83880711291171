.services-nav-wrapper {
    width: 100%;
    background-color: $dark-background;
}
.services-nav {
    display: flex;
    // max-width: 1240px;
    margin: 0 auto;
    button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        padding: 2.5rem;
        color: rgb(198, 198, 198);
        background-color: $dark-background;
        font-size: 1.3rem;
        text-transform: uppercase;
        border: 0;
        font-weight: 600;
        cursor: pointer;
        span {
            font-size: 0.8rem;
            @media (min-width: map-get($grid-breakpoints, sm)) {
                font-size: 1.3rem;
                display: inline-block;
            }
        }
    }
    button:hover {
        background-color: $subtitle-underline;
        // img {
        // filter: invert(0.9);
        // will-change: transform;
        // }
    }
    img {
        max-width: 30px;
        color: $white;
        filter: invert(50%);
        padding-bottom: 2rem;
        will-change: transform;
    }

    .active-page {
        // color: $white !important;
        font-weight: 800;
        background-color: $subtitle-underline;
        // img {
        //     filter: invert(0.9);
        //     will-change: transform;
        // }
    }
}
